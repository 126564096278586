import React from "react";
import img2 from "../assets/img/partners/aws.png";
import img3 from "../assets/img/partners/cover-compared.png";
import img4 from "../assets/img/partners/cryptocoin.png";
import img5 from "../assets/img/partners/ibm.png";
import img6 from "../assets/img/partners/ime.png";
import img7 from "../assets/img/partners/link.png";
import img10 from "../assets/img/partners/safepal.svg";
import img11 from "../assets/img/partners/synthy2.svg";
import img1 from "../assets/img/partners/VD.png";
import img8 from "../assets/img/partners/veriff.png";
import img9 from "../assets/img/partners/zitec.png";
import img12 from "../assets/img/partners/altura.png";

const data = [
	img1,
	img2,
	img3,
	img4,
	img5,
	img6,
	img7,
	img11,
	img9,
	img8,
	img10,
	img12
];

const Echosystem = () => {
	return (
		<>
			<section className="pb-5">
				<div className="container pb-lg-5">
					<h2 className="title text-center mb-4 mb-lg-5">Ecosystem</h2>
					<div className="partner-wrapper">
						{data?.map((item, i) => (
							<img src={item} key={i} alt="" />
						))}
					</div>
				</div>
			</section>
		</>
	);
};

export default Echosystem;
