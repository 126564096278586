import React from "react";
const ComingSoon = () => {
	return (
		<section className="banner-section">
			<div className="banner-top">
				<div className="container">
					<div className="banner-content">
						<h1 className="title">Coming Soon...</h1>
						<h3>For your kind information, we're launching soon</h3>
					</div>
				</div>
			</div>
		</section>
	);
};

export default ComingSoon;
