import React from "react";
import BannerSection from "../../components/BannerSection";
import Echosystem from "../../components/Echosystem";
import InfoSection from "../../components/InfoSection";
import WeCreateSection from "../../components/WeCreateSection";
import WeHere from "../../components/WeHere";

const Home = () => {
	return (
		<>
			<BannerSection />
			<WeHere />
			<WeCreateSection />
			<InfoSection />
			<Echosystem />
		</>
	);
};

export default Home;
