import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.scss";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Buylanc from "./pages/BuyLanc/BuyLanc";
import ComingSoon from "./pages/ComingSoon/ComingSoon";
import Home from "./pages/Home/Home";

function App() {
	return (
		<>
			<BrowserRouter>
				<Header />
				<Routes>
					<Route path="/" element={<Home />} />
					<Route path="/buy-lanc" element={<Buylanc />} />
					<Route path="/*" element={<ComingSoon />} />
				</Routes>
				<Footer />
			</BrowserRouter>
		</>
	);
}

export default App;
