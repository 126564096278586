/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useEffect, useState } from "react";

const BuyLanc = () => {
	const [loading, setLoading] = useState(true);
	useEffect(() => {
		setTimeout(() => {
			setLoading(false);
		}, 2000);
	}, []);

	return (
		<>
			{loading && (
				<div className="loader">
					<div className="spinner-border text-primary" role="status">
						<span className="visually-hidden">Loading...</span>
					</div>
				</div>
			)}

			<div className="py-5">
				<div className="container">
					<div
						dangerouslySetInnerHTML={{
							__html: `
									<iframe
										src="https://checkout.cryptocoin.pro/order/lanceria?iframe=true&theme=light"
										style="height:calc(100vh - 176px)"
										width="100%"
									></iframe>`,
						}}
					/>
				</div>
			</div>
		</>
	);
};

export default BuyLanc;
