import React from "react";
import { Link } from "react-router-dom";
import img from "../assets/img/banner/banner-2.png";
import bg_img from "../assets/img/banner/bg-banner.png";

import img3 from "../assets/img/banner/caps.png";
import img2 from "../assets/img/banner/check.png";
import img1 from "../assets/img/banner/claps.png";
import img4 from "../assets/img/banner/token.png";
import { LeftStar, RightStar } from "../ThemeIcon";

const data = [
	{
		img: img1,
		title: "Work-life balance",
		text: "Manage your work and life schedule within the same platform.",
	},
	{
		img: img2,
		title: "Verified professionals",
		text: "Match with a professional fit for your project and verify their skill level.",
	},
	{
		img: img3,
		title: "Teaching hubs",
		text: "Receive valuable benefits by teaching fellow freelancers.",
	},
	{
		img: img4,
		title: "Tokenized economy",
		text: "Access utility tokens and discover a gamified work process.",
	},
];

const BannerSection = () => {
	return (
		<>
			<section className="banner-section">
				<div className="banner-top">
					<div className="container">
						<div className="banner-content">
							<Link to="#" className="subtitle">
								<LeftStar />
								The future of work
								<RightStar />
							</Link>
							<h1 className="title">Freelance your way through life</h1>
							<div className="text">
								Join the movement for fair P2P payments and step away
								from the classic workplace or unfair terms from the
								freelancing industry. No hidden fees, just the freedom
								and community to showcase your work.
							</div>
						</div>
					</div>
				</div>
				<div
					className="banner-bottom"
					style={{
						background: `url(${bg_img}) no-repeat center center / contain`,
					}}
				>
					<div className="container">
						<img src={img} className="banner-img" alt="" />
						<div className="banner-wrapper">
							{data?.map((item, i) => (
								<BannerCard key={i} {...item} />
							))}
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export const BannerCard = ({ img, title, text }) => (
	<div className="banner-card">
		<div className="banner-card-inner">
			<h4 className="subtitle">
				<img src={img} alt="" />
				<span>{title}</span>
			</h4>
			<p>{text}</p>
		</div>
	</div>
);

export default BannerSection;
