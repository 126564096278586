import React from "react";
import { Link } from "react-router-dom";
import {
  Discord,
  Facebook,
  Reddit,
  Telegram,
  Twitter,
  Youtube,
} from "../ThemeIcon";
const data = [
  {
    icon: <Youtube />,
    name: "Youtube",
    link: "https://www.youtube.com/@Lancerialabs",
  },
  {
    icon: <Twitter />,
    name: "Twitter",
    link: "https://twitter.com/lancerialabs",
  },
  {
    icon: <Telegram />,
    name: "Telegram",
    link: "https://t.me/lanceria",
  },
  {
    icon: <Facebook />,
    name: "Facebook",
    link: "https://www.facebook.com/lancerialabs",
  },
];
const SocialIcons = () => {
  return (
    <>
      <div className="social-icons d-flex justify-content-center">
        {data?.map(({ icon, name, link }, i) => (
          <a target="_blank" rel="noreferrer" href={link} key={i}>
            <div className="icon">{icon}</div>
            <div className="icon">{name}</div>
          </a>
        ))}
      </div>
    </>
  );
};

export default SocialIcons;
