import React, { useState } from "react";
import Marquee from "react-fast-marquee";
import img1 from "../assets/img/animated/1.png";
import img2 from "../assets/img/animated/2.png";
import img3 from "../assets/img/animated/3.png";
import img4 from "../assets/img/animated/4.png";
import img5 from "../assets/img/animated/5.png";
import img6 from "../assets/img/animated/6.png";
const data = [img1, img2, img3, img4, img5, img6];
const WeCreateSection = () => {
	const [direction, setDirection] = useState(false);
	return (
		<section className="default-padding">
			<div className="section-title text-center mb-4">
				<h2 className="title">We create</h2>
			</div>
			<div className="collections-img">
				<Marquee
					pauseOnHover
					speed={140}
					gradientColor={false}
					direction={direction ? "left" : "right"}
					onCycleComplete={() => setDirection(!direction)}
				>
					{data?.map((item, i) => (
						<div key={i}>
							<img src={item} alt="" />
						</div>
					))}
				</Marquee>
			</div>
		</section>
	);
};

export default WeCreateSection;
