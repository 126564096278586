import React from "react";
import { Link } from "react-router-dom";
import img1 from "../assets/img/info/info1.png";
import img2 from "../assets/img/info/info2.png";
import img3 from "../assets/img/info/info3.png";
import { PaymentIcon, PlatformIcon, VerificationIcon } from "../ThemeIcon";

const data = [
  {
    icon: <PaymentIcon />,
    name: "Payment",
    title: "Safe payment through Escrow and chain",
    text: "Enjoy immediate payment processing that allows you to claim your rewards as soon as your work or milestone is completed. The Escrow Wallet stores your cryptocurrency reward and automatically verifies payments through TNXid.",
    btnText: "Learn More",
    btnLink:
      "https://lanceria.medium.com/lanceria-is-now-a-data-provider-on-the-chainlink-network-25ccba64df50",
    img: img1,
  },
  {
    icon: <VerificationIcon />,
    name: "Verification",
    title: "Securely verify your identity through Veriff",
    text: "Feel safe and secure knowing that our freelancers are real people with real identities. We use Veriff to make sure that our ecosystem is growing safely and honestly.",
    btnText: "How It Works",
    btnLink: "https://www.veriff.com/product/identity-verification",
    img: img2,
  },
  {
    icon: <PlatformIcon />,
    name: "Platform",
    title: "Decentralized platform and blockchain",
    text: "Freelancing is the future of the work. And it starts with a decentralized platform where you can work effortlessly, a cryptocurrency payment system, incentive schemes and an engaging community.  ",
    btnText: "Read More",
    btnLink: "Freelancepaper.pdf",
    img: img3,
    pdf: true,
  },
];
const InfoSection = () => {
  return (
    <section className="info-section">
      <div className="container">
        {data?.map(
          ({ img, icon, name, title, text, btnText, btnLink, pdf }, i) => (
            <div className="default-padding" key={i}>
              <div
                className={`row g-3 align-items-center ${
                  i % 2 === 1 ? "" : "flex-row-reverse"
                }`}
              >
                <div
                  className={`col-md-5 text-center ${
                    i % 2 === 1 ? "text-md-start" : "text-md-end"
                  }`}
                >
                  <img className="info-section-img" src={img} alt="" />
                </div>
                <div className="col-md-7">
                  <div className="info-section-content">
                    <h5 className="name">
                      {icon} {name}
                    </h5>
                    {title && <h2 className="title">{title}</h2>}
                    {text && <p>{text}</p>}
                    {btnLink && (
                      <a
                        target={pdf ? null : "_blank"}
                        rel="noreferrer"
                        href={btnLink}
                        className="btn btn-base"
                      >
                        {btnText}
                      </a>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )
        )}
      </div>
    </section>
  );
};

export default InfoSection;
