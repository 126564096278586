import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../assets/img/logo-white.png";
import img from "../assets/img/post-img.png";
import {
	AngleRight,
	AngleRightSm,
	Bar,
	Careers,
	Close,
	Contact,
	FutureOfWork,
	HowItWork,
	LeftStar,
	Media,
	RightStar,
	Team,
} from "../ThemeIcon";
const data = [
	{
		name: "Search portfolios",
		link: "/portfolio",
	},
	{
		name: "The future of work",
		link: "https://app.lanceria.com/",
		hasSubmenu: true,
	},
	{
		name: "Freelancepaper",
		link: "#",
		pdf: true,
	},
	{
		name: "Metaverse",
		link: "/metaverse",
	},
	{
		name: "NFT Marketplace",
		link: "/marketplace",
	},
];
const subMenuData = [
	{
		icon: <FutureOfWork />,
		text: "The future of work",
		link: "#",
	},
	{
		icon: <HowItWork />,
		text: "How it works",
		link: "#",
	},
	{
		icon: <Team />,
		text: "Our Team",
		link: "#",
	},
	{
		icon: <Media />,
		text: "Media",
		link: "#",
	},
	{
		icon: <Careers />,
		text: "Careers",
		link: "#",
	},
	{
		icon: <Contact />,
		text: "Contact",
		link: "#",
	},
];

const Header = () => {
	const [menuOpen, setMenuOpen] = useState(false);
	const [subMenuOpen, setSubMenuOpen] = useState(false);

	const openPdf = () => {
		window.open("/Freelancepaper.pdf", "_self");
	};

	return (
		<header>
			<div className="container">
				<div className="header-wrapper">
					<Link to="/" className="logo">
						<img src={logo} alt="" />
					</Link>
					<div className={`menu-area ${menuOpen ? "active" : ""}`}>
						<ul className="menu">
							{data?.map(({ name, link, hasSubmenu, pdf }, i) => (
								<li key={i}>
									{hasSubmenu ? (
										<Link
											to={link}
											// onClick={() => setSubMenuOpen(true)}
										>
											{hasSubmenu && <LeftStar />}
											<span>{name}</span>
											{hasSubmenu && <RightStar />}
										</Link>
									) : (
										<Link onClick={pdf ? openPdf : null} to={link}>
											<span>{name}</span>
										</Link>
									)}
									{hasSubmenu && (
										<PopupMenu
											data={subMenuData}
											subMenuOpen={subMenuOpen}
											setSubMenuOpen={setSubMenuOpen}
										/>
									)}
								</li>
							))}
							<span
								className="close-icon"
								onClick={() => setMenuOpen(false)}
							>
								<Close />
							</span>
						</ul>
					</div>
					<div className="right-icons">
						<div onClick={() => setMenuOpen(true)} className="bar">
							<Bar />
						</div>
						<Link
							to="https://app.lanceria.com/"
							className="author bg-3C36A0"
							style={{
								width: "auto",
								aspectRatio: "unset",
								borderRadius: "20px",
								padding: "10px 20px",
								color: "#fff",
							}}
						>
							Enter App
						</Link>

						{/* <div className="balance-card">
							<BalanceCard />
							<strong>0</strong>
							<span>LANC</span>
						</div>
						<Link to="#" className="author bg-3C36A0">
							<Bell />
						</Link>
						<Link to="#" className="author">
							<span className="status">12</span>
							<Message />
						</Link>
						<Link to="#" className="author">
							<img src={author} alt="" />
						</Link> */}
					</div>
				</div>
			</div>
		</header>
	);
};

export const PopupMenu = ({ data, subMenuOpen, setSubMenuOpen }) => (
	<>
		<div
			className={`off-canvas ${subMenuOpen ? "active" : ""}`}
			onClick={() => setSubMenuOpen(false)}
		></div>
		<div className={`submenu ${subMenuOpen ? "active" : ""}`}>
			<div className="submenu-top">
				<ul>
					{data?.map(({ icon, text, link }, i) => (
						<li key={i}>
							<Link to="#" key={i}>
								{icon} <span>{text}</span> <AngleRightSm />
							</Link>
						</li>
					))}
				</ul>
			</div>
			<div className="submenu-bottom">
				<span className="subtitle">Latest news</span>
				<Link to="#" className="latest-item">
					<img src={img} alt="" />
					<div className="latest-item-info">
						<span className="info">20 Ian 2022</span>
						<h6 className="title">Lanceria just bridged with Polygon</h6>
						<div className="text">
							A six week, part-time, online training program that covers
							the core aspects of Lanceria
						</div>
					</div>
					<AngleRight />
				</Link>
			</div>
		</div>
	</>
);
export default Header;
