import React from "react";
import { Link } from "react-router-dom";
import img from "../assets/img/footer-bg.png";
import SocialIcons from "./SocialIcons";
const Footer = () => {
  return (
    <footer>
      <div className="footer-top">
        <div className="container">
          <div
            className="footer-top-wrapper"
            style={{
              background: `url(${img}) #000 no-repeat center center / cover`,
            }}
          >
            <h2 className="title">Join our fast growing Lanceria community</h2>
            <SocialIcons />
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="container text-center">
          <ul className="privacy-links">
            <li>
              <a href="terms-and-condition.pdf">Terms & Conditions</a>
            </li>
            <li>
              <a href="Privacy_Policy.pdf">Privacy Policy</a>
            </li>
            <li>
              <a href="Cookie_Policy.pdf">Cookies Policy</a>
            </li>
          </ul>
          <div>&copy; 2024 Lanceria.®. All Rights Reserved.</div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
