import React from "react";
import img1 from "../assets/img/we-here/we1.png";
import img2 from "../assets/img/we-here/we2.png";

const data = [
	{
		img: img1,
		title: "350,000,000 LANC",
		text: "pre-mined in the Genesys.",
	},
	{
		img: img2,
		title: "$4.2 TRILLION",
		text: "The size of the worldwide freelancing economy.",
	},
];

const WeHere = () => {
	return (
		<section className="default-padding">
			<div className="container">
				<div className="row gy-4 align-items-center">
					<div className="col-lg-6 col-md-5 pe-xl-4">
						<div className="section-title">
							<h2 className="mb-3">
								We're here to support the way people work
							</h2>
							<p>
								Be part in our crusade of building the world’s fairest,
								safest and easiest to use decentralized marketplace
								platform for freelancers.
							</p>
						</div>
					</div>
					<div className="col-lg-6 col-md-7 ps-xl-4">
						<div className="row g-4">
							{data?.map(({ img, title, text }, i) => (
								<div className="col-md-12" key={i}>
									<div className="we-here-card">
										<img src={img} alt="" />
										<div className="cont">
											<h2 className="title">{title}</h2>
											<p>{text}</p>
										</div>
									</div>
								</div>
							))}
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default WeHere;
